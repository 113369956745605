<script setup lang="ts">
import type { TheHeaderSwitchContent } from '#build/components'

const { languageCurrencyOpen, setLanguageCurrencyOpen } = useHeaderV2()

const switchRef = ref<InstanceType<typeof TheHeaderSwitchContent> | null>(null)

const handleValidate = () => {
  switchRef.value?.handleValidate()
}
</script>

<template>
  <BaseModalV2
    :model-value="languageCurrencyOpen"
    :primary-button="$t('global.validate')"
    @primary-button="handleValidate"
    @update:model-value="setLanguageCurrencyOpen($event)"
  >
    <template #content>
      <div>
        <TheHeaderSwitchContent
          ref="switchRef"
          hide-validate-button
          @validated="setLanguageCurrencyOpen(false)"
        />
      </div>
    </template>
  </BaseModalV2>
</template>
