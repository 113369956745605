<script setup lang="ts">
import type { Locale } from 'lc-services/types'

const localePath = useLocalePath()
const { locale, t } = useI18n<unknown, Locale>()

const { setMenuOpen } = useHeaderV2()

const links = [
  { name: t('headerAbout.links.howItWorks'), to: localePath('customHolidays') },
  { name: t('headerAbout.links.ourAddedValue'), to: localePath('addedValue') },
  {
    name: t('headerAbout.links.responsibleCompany'),
    href:
      locale.value === 'fr'
        ? 'https://selection.lecollectionist.com/b-corp'
        : 'https://selection.lecollectionist.com/en/b-corp',
  },
]
</script>

<template>
  <div class="flex h-full flex-col justify-between px-6">
    <div>
      <div class="font-monospace mb-7 text-xl">
        {{ $t('header.aboutUs') }}
      </div>
      <ul>
        <li v-for="link in links" :key="link.id">
          <NuxtLink
            v-if="link.to"
            class="relative inline-block py-1.5 text-md leading-6 text-gray-700 after:absolute after:bottom-1.5 after:left-0 after:h-px after:w-full after:origin-bottom-left after:scale-x-0 after:bg-secondary-400 after:opacity-0 after:transition-all after:duration-500 after:ease-out after:content-[''] hover:text-secondary-400 hover:no-underline hover:after:scale-x-100 hover:after:opacity-100"
            :to="link.to"
            @click.prevent="setMenuOpen(false)"
          >
            {{ link.name }}
          </NuxtLink>
          <NuxtLink
            v-else
            :href="link.href"
            class="relative inline-block py-1.5 text-md leading-6 text-gray-700 after:absolute after:bottom-1.5 after:left-0 after:h-px after:w-full after:origin-bottom-left after:scale-x-0 after:bg-secondary-400 after:opacity-0 after:transition-all after:duration-500 after:ease-out after:content-[''] hover:text-secondary-400 hover:no-underline hover:after:scale-x-100 hover:after:opacity-100"
            target="_blank"
          >
            {{ link.name }}
          </NuxtLink>
        </li>
      </ul>
    </div>
  </div>
</template>
