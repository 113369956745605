<script setup lang="ts">
const { searchMobileOpen, setSearchMobileOpen } = useHeaderV2()
const { goToSearch } = useSearchInput()

const redirectSearch = () => {
  goToSearch()
  setSearchMobileOpen(false)
}
</script>

<template>
  <BaseModalV2
    :has-close-button="false"
    :model-value="searchMobileOpen"
    mobile-full-height
  >
    <template #content>
      <SearchInputV2MobileLocation
        @cancel="setSearchMobileOpen(false)"
        @selected="redirectSearch"
      />
    </template>
  </BaseModalV2>
</template>
